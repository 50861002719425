.mat-button-base:not([mat-icon-button]) {
  border-radius: 0;
  font-size: clamp(0.8rem, 2vmin, 0.9rem);
  font-family: var(--ff-sans-serif);
  line-height: 3rem;
  padding: 0 2rem;
  text-transform: uppercase;

  & * {
    font-family: var(--ff-sans-serif);
  }
}
