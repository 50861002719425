.partner {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;

  @include media('>=900px') {
    grid-template-columns: 1fr 1fr;
  }

  .mat-card {
    padding: 2em;
    display: flex;
    flex-direction: column;
    flex: 1;

    p {
      max-inline-size: unset;
    }
  }

  .mat-card-image {
    display: flex;
    max-height: 200px;
    max-width: 100%;
    width: auto;
    margin: auto !important;
    border-radius: unset !important;
    margin-bottom: 1.5em !important;
  }

  .mat-card-header-text {
    margin: 0;
  }

  .mat-card-subtitle a {
    display: inline-block;
    width: 100%;
    max-width: calc(100vw - 8em) !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include media('>=900px') {
      max-width: calc(50vw - 10em) !important;
    }
  }
}

shop-partner {
  display: flex;
}
