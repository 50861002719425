header {
  width: 100%;
  margin-bottom: 1em;

  @include media('>=768px') {
    margin-top: 1em;
  }

  @include media('>=870px') {
    margin-top: 0;
  }

  .container {
    display: unset;
  }
}

.startpage header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 0;
}

nav.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  @include media('>=960px') {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
  }

  a {
    color: var(--text-default) !important;
    font-family: var(--ff-sans-serif);
    text-transform: uppercase;
    letter-spacing: 0.25px;
    text-decoration: none;
    padding: 4px 0;
    border-bottom: 2px solid transparent;
    transition: border-color var(--d) var(--e);
    user-select: none;

    @include media('>=960px') {
      font-size: 0.875rem;
    }

    @include media('>=desktop') {
      font-size: 1rem;
    }

    &:hover,
    &:active,
    &.active {
      color: var(--text-default) !important;
    }

    &.sport {
      &:hover,
      &:active,
      &.active {
        border-bottom-color: var(--color-sport);
      }

      + ul a {
        transition: color var(--d) var(--e);

        &:hover {
          color: var(--color-sport) !important;
        }
      }
    }

    &.culture {
      &:hover,
      &:active,
      &.active {
        border-bottom-color: var(--color-culture) !important;
      }
    }

    &.horse {
      &:hover,
      &:active,
      &.active {
        border-bottom-color: var(--color-horse) !important;
      }
    }
  }

  li {
    max-inline-size: unset;
  }

  li.dropdown {
    position: relative;

    &:hover {
      ul {
        display: grid;
      }
    }

    > a {
      position: relative;

      &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23948F96' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3E%3C/svg%3E");
        display: inline-block;
        width: 1.15rem;
        height: 1.15rem;
        vertical-align: text-bottom;
        transform: translateX(5px);
      }
    }

    ul {
      position: absolute;
      gap: 0;
      top: 1.75em;
      left: 0;
      display: none;
      padding: 1em 0 0 0;
      list-style: none;
      box-shadow: 6px 12px 6px rgba(0, 0, 0, 0.2);
      transform: translateX(-0.75rem);
      z-index: 100;

      li {
        width: 100%;
        background-color: white;

        a {
          display: block;
          padding: 0.4rem 0.75rem;
          margin: 0;
          border: none;
        }
      }
    }
  }

  .logo {
    display: none;
    padding: 0;
    margin: 10px 0;
    border-bottom: none !important;
    user-select: none;

    @include media('>=870px') {
      margin: 20px 0;
    }

    &:hover,
    &:active,
    &:focus {
      border-bottom: none !important;
      user-select: none;
      background: transparent;
    }

    svg {
      height: 30px;
      margin: 0.5em 0 0 0;
      user-select: none;

      g,
      path {
        user-select: none;
      }

      @include media('>=768px') {
        height: 40px;
      }

      @include media('>=870px') {
        margin: 0;
      }

      @include media('>=90em') {
        height: 56px;
      }
    }
  }

  .nav-left,
  .nav-right {
    list-style: none;
    gap: 2.5rem;
    grid-auto-flow: column;
    padding: 0;
    margin: 0 0 0.5rem 0;
  }

  .nav-left {
    display: none;

    @include media('>=960px') {
      display: grid;
    }
  }

  .nav-mid {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-right {
    display: none;
    justify-self: flex-end;

    @include media('>=960px') {
      display: grid;
    }

    a {
      &:hover,
      &:active,
      &.active {
        border-color: var(--color-neutral);
      }
    }
  }

  .hamburger {
    @include media('>=960px') {
      display: none;
    }
  }
}

// Mobile Navigation
.mat-drawer {
  width: 280px;
  background-color: #1a1a1a !important;

  .spacer {
    flex: 1 1 auto;
  }
}

.mat-toolbar,
.mat-nav-list {
  background-color: #1a1a1a !important;
}

.mat-toolbar {
  padding-top: 3px;
  @include theme(color, primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mat-toolbar-row {
  padding: 0 1rem 0 2rem !important;
  font-size: 1rem;
}

.mat-list-item-content {
  padding: 0 2rem !important;
}

.mat-nav-list {
  .mat-line {
    color: white !important;
  }

  .secondary {
    .mat-line {
      padding-left: 2rem !important;
    }
  }
}

// Toggle the logos respectively
.theme-default,
.theme-sport {
  nav.main .logo__sport {
    display: block;
  }
}

.theme-culture {
  nav.main .logo__culture {
    display: block;
  }
}

.theme-horse {
  nav.main .logo__horse {
    display: block;
  }
}
