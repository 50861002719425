.breadcrumb {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  padding: 0;
  margin: -1em 0 1em 0;

  @include media('<tablet') {
    display: none;
  }

  &__item {
    &::after {
      content: '/';
      display: inline-block;
      margin: 0 0 0 0.5em;
      opacity: 0.75;
    }

    a {
      font-family: var(--ff-sans-serif);
      font-size: 0.85rem;
      text-decoration: none;
      line-height: 1;
      padding: 0.25em 0;
      @include theme(color, primary);
      border-bottom: 1px solid transparent;
      transition: all var(--d) var(--e);

      &:active,
      &:visited,
      &:hover {
        text-decoration: none;
        @include theme(color, primary);
      }

      &:hover {
        @include theme(border-bottom-color, primary);
      }
    }

    &--active {
      &::after {
        display: none;
      }

      a {
        color: var(--text-default) !important;
        border: none;

        &:hover {
          border: none;
        }
      }
    }
  }
}
