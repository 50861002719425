.mat-mdc-select-panel {
  background: white;
  max-width: 360px;

  &:not([class*='mat-elevation-z']) {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.141), 0 1px 10px rgba(0, 0, 0, 0.122);
  }
}

.mat-checkbox {
  margin-bottom: 10px;
}

.mat-checkbox-inner-container {
  margin-top: 4px !important;
}
