.form {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  padding: 1em;
  margin: 2em 0 4em 0;
  background-color: white;

  @include media('>tablet') {
    padding: 2em;
    row-gap: 0.5em;
    max-inline-size: 75%;
    grid-template-columns: 1fr 1fr;
    column-gap: 2em;
  }

  .full-width {
    @include media('>tablet') {
      grid-column-end: span 2;
    }
  }

  textarea {
    height: 150px;
    font-size: 0.875rem !important;
  }
}

.mat-form-field {
  font-size: 0.875rem;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0.5em 0.75em 0 0.75em !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.5em 0 !important;
}
