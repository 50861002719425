.categrory-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1em;

  .product-grid {
    margin: 2em 0 3em 0;
  }
}

.product-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  align-items: center;
  row-gap: 4em;
  margin: 3em 0 6em 0;

  @include media('>=768px') {
    grid-template-columns: 1fr 1fr;
    column-gap: 4em;
  }

  @include media('>desktop') {
    column-gap: 6em;
  }

  shop-eventticket-highlight {
    padding: 0 0.5em;

    .product {
      max-width: unset;
    }
  }
}

.product {
  position: relative;
  width: 100%;
  max-width: 400px;

  @include media('>=768px') {
    max-width: 540px;
  }

  a {
    display: block;
    text-decoration: none;
  }

  &__image {
    position: relative;
    padding-top: 56.25%;
    transform: translateX(-0.5rem);
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;

    &::after {
      position: absolute;
      content: '';
      display: block;
      background-color: rgba(255, 255, 255, 0.9);
      width: 100%;
      height: 100%;
      bottom: -4.25rem;
      right: -1rem;
      z-index: -1;
      box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
      transition: background-color var(--d) var(--e);

      @include media('>=768px') {
        bottom: -5.5rem;
      }
    }

    img {
      display: block;
      position: absolute !important;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__infos {
    position: relative;
    width: 100%;
    height: 100%;
    bottom: 0;
    padding: 0.5rem 3rem 0.5rem 2rem;
    display: grid;
    gap: 0.4rem;
    font-weight: 500;
    line-height: 1;
    z-index: 1;

    @include media('>=768px') {
      padding: 1rem 3rem 1rem 2rem;
    }

    &--date {
      @include theme(color, primary);
      font-size: 0.7rem;

      @include media('>=768px') {
        font-size: 0.8rem;
      }
    }

    &--title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.8rem;
      color: var(--text-default);

      @include media('>=768px') {
        font-size: 1rem;
      }
    }

    &--price {
      font-weight: normal;
      font-size: 0.8rem;
      color: var(--text-default);

      @include media('>=768px') {
        font-size: 1rem;
      }
    }
  }

  &__arrow.mat-icon {
    position: absolute !important;
    right: -0.5rem;
    bottom: 0.4rem;
    width: 50px !important;
    height: 50px !important;
    fill: var(--text-default);
    z-index: 1;
    transition: fill var(--d) var(--e);

    @include media('>=768px') {
      width: 60px !important;
      height: 60px !important;
      bottom: 1rem;
    }
  }

  &__highlight::before,
  &__soldout::before {
    position: absolute;
    display: block;
    padding: 0.5rem 2rem 0.5rem 0.75rem;
    color: white;
    font-size: clamp(0.75rem, 2vmin, 0.8rem);
    font-weight: 500;
    text-transform: uppercase;
    top: 0;
    left: -0.5rem;
    z-index: 2;
    clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
    pointer-events: none;
  }

  &__highlight::before {
    content: 'Top-Angebot';
    @include theme(background-color, primary);
  }

  &__soldout::before {
    content: 'Warteliste';
    background: rgb(255 59 0 / 80%) !important;
  }
}

.product:hover {
  .product__image::after {
    background-color: rgba(255, 255, 255, 1);
  }

  .product__arrow.mat-icon {
    @include theme(fill, primary);
  }

  .product__infos--title,
  .product__infos--price {
    @include theme(color, primary);
  }
}

.product__detail {
  display: grid;
  gap: 2em;
  padding: 1em 0 3em 0;

  &--header {
    position: relative;
    padding-top: 80%;
    z-index: 1;
    margin-top: -1em;

    @include media('>480px') {
      padding-top: 60%;
    }

    @include media('>=640px') {
      padding-top: 40vw;
    }

    @include media('>=1024px') {
      padding-top: 30vw;
    }

    @include media('>=1440px') {
      padding-top: 25%;
    }

    &-image {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        object-fit: cover;
      }
    }

    h1 {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      display: inline-block;
      padding: 0.25em 0.5em;
      color: white;
      @include theme(background-color, primary);
      max-inline-size: calc(100% - 1em);
      opacity: 0.9;
    }

    .gallery-button {
      position: absolute;
      bottom: 1em;
      left: 1em;
      padding: 0 1em;
      line-height: 2.6;

      @include media('>=desktop') {
        bottom: 1.5em;
        left: 1.5em;
      }

      .mat-button-wrapper::after {
        display: inline-block;
        content: '+';
        margin-left: 1em;
      }
    }
  }

  &--info {
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr;

    @include media('>=tablet') {
      gap: 4em;
      grid-template-columns: 1fr 1fr;
    }

    @include media('>1440px') {
      grid-template-columns: 2fr 1fr;
    }

    .col-left {
      overflow: hidden;
    }

    p {
      max-inline-size: unset;
    }

    &-card {
      padding: 2em;
      background-color: white;
      display: grid;
      grid-template-columns: auto;
      gap: 2em;
      justify-content: flex-start;

      .mat-icon {
        @include media('>=tablet') {
          width: 2em;
          height: 2em;
        }

        svg {
          fill: #ccc;
        }
      }

      > div {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        font-size: 1.125rem;

        @include media('>=tablet') {
          font-size: 1.25rem;
        }

        span {
          display: block;
          margin-top: 0.5em;
          font-size: 0.75rem;
          color: var(--text-default);

          @include media('>=tablet') {
            font-size: 0.875rem;
          }

          & + span {
            margin-top: 0;
          }
        }
      }

      .price {
        color: #41bd64;
      }
    }
  }

  .usps {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
    align-items: center;
    margin: 2em 0;
    gap: 1em;

    @include media('>500px') {
      display: flex;
      flex-direction: row;
    }

    .usp {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      gap: 0.5em;

      .mat-icon {
        width: 2em;
        height: 2em;

        @include media('>500px') {
          width: 3em;
          height: 3em;
        }
      }

      em {
        font-size: 0.875rem;

        @include media('>500px') {
          font-size: 1rem;
        }
      }
    }
  }

  .downloads {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1em;

    @include media('>500px') {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4em;
    }

    .download {
      display: inline-flex;
      align-items: center;
      gap: 1em;

      .mat-icon {
        width: 1.5rem;
        height: 1.5rem;

        @include media('>500px') {
          width: 2rem;
          height: 2rem;
        }

        svg {
          fill: var(--text-default);
        }
      }
    }
  }

  .h3 {
    margin-top: 1em;
  }
}

.mat-accordion {
  @include media('>desktop') {
    max-inline-size: 75%;
  }

  p {
    font-size: 0.875rem;
  }
}
