// Needs to be imported first!
@import 'themes.scss';

// Components
@import 'breakpoints.scss';
@import 'typography.scss';
@import 'breadcrumb.scss';
@import 'button.scss';
@import 'dialog.scss';
@import 'forms.scss';
@import 'contact.scss';
@import 'footer.scss';
@import 'header.scss';
@import 'lightbox.scss';
@import 'products.scss';
@import 'startpage.scss';
@import 'partner.scss';

:root {
  --text-default: hsl(285, 8%, 10%);
  --ff-sans-serif: 'Roboto', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  --ff-slab: 'Roboto Slab', 'Georgia', Times, serif;
  --color-sport: hsl(197, 78%, 52%);
  --color-culture: hsl(351, 84%, 61%);
  --color-horse: hsl(103, 45%, 58%);
  --color-neutral: hsl(300, 1%, 68%);
  --color-warning: hsl(4, 90%, 58%);
  --color-success: #41bd64;
  --d: 350ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--ff-sans-serif);
  font-size: 16px;
  font-weight: 400;
  overscroll-behavior: none;

  &.loading {
    visibility: hidden;
  }
}

body {
  overflow-y: scroll;
  background: #212121;

  shop-main > div {
    animation: fadeIn 0.5s;
  }

  .lb-data .lb-caption {
    font-weight: normal;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.inner-text {
  display: grid;
  gap: 1em;
}

.highlight {
  color: #ef4760;
}

.container {
  display: grid;
  gap: 1em;
  padding: 0 1.25em;
  margin: 0 auto;

  @include media('>=768px') {
    padding: 0 3em;
  }

  @include media('>tablet') {
    max-width: 960px;
  }

  @include media('>90em') {
    padding: 0;
    max-width: 1280px;
  }

  @include media('>110em') {
    max-width: 1400px;
  }
}

.mat-checkbox-layout {
  white-space: normal !important;
}

section.container {
  padding-bottom: 3em;
}

main {
  position: relative;
}

.splash {
  .mat-button-base {
    max-width: 250px;
  }
}

.startpage main {
  background-color: var(--text-default);
  min-height: 100px;
}

mat-drawer-content {
  display: grid !important;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  background: #f3f3f3;
}

.color-sport {
  color: var(--color-sport) !important;
}

.color-culture {
  color: var(--color-culture) !important;
}

.color-horse {
  color: var(--color-horse) !important;
}

.color-warning {
  color: var(--color-warning) !important;
}

.color-success {
  color: var(--color-success) !important;
}
