@use 'sass:map';
@import '@angular/material/theming';

@include mat-core();

$default-palette: (
  50: #f8f7f8,
  100: #f0eff0,
  200: #e4e3e4,
  300: #d3d2d3,
  400: #aeadae,
  500: #8d8c8d,
  600: #666566,
  700: #535253,
  800: #353435,
  900: #151415,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$sport-palette: (
  50: #e0f4fb,
  100: #b1e2f5,
  200: #7ecfee,
  300: #4dbce7,
  400: #27aee4,
  500: #00a1e0,
  600: #0093d2,
  700: #0081bf,
  800: #0070ab,
  900: #00518b,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$culture-palette: (
  50: #fdeaf0,
  100: #fccbd7,
  200: #ed96a2,
  300: #e36d7e,
  400: #ef4760,
  500: #f62d49,
  600: #e62247,
  700: #d41640,
  800: #c70a38,
  900: #b9002d,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$horse-palette: (
  50: #edf7e9,
  100: #d2ebc8,
  200: #b5dda4,
  300: #97cf80,
  400: #80c565,
  500: #6abb4b,
  600: #5cab43,
  700: #499739,
  800: #37832f,
  900: #11611e,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$default-primary: mat-palette($default-palette, 400);
$sport-primary: mat-palette($sport-palette, 400);
$culture-primary: mat-palette($culture-palette, 400);
$horse-primary: mat-palette($horse-palette, 400);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme-default: mat-light-theme(
  (
    color: (
      primary: $default-primary,
      accent: $default-primary,
    ),
  )
);

$theme-sport: mat-light-theme(
  (
    color: (
      primary: $sport-primary,
      accent: $sport-primary,
    ),
  )
);

$theme-culture: mat-light-theme(
  (
    color: (
      primary: $culture-primary,
      accent: $culture-primary,
    ),
  )
);

$theme-horse: mat-light-theme(
  (
    color: (
      primary: $horse-primary,
      accent: $horse-primary,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
.theme-default {
  @include angular-material-theme($theme-default);
}

.theme-sport {
  @include angular-material-theme($theme-sport);
}

.theme-culture {
  @include angular-material-theme($theme-culture);
}

.theme-horse {
  @include angular-material-theme($theme-horse);
}

@mixin theme($property: null, $key: null) {
  .theme-default & {
    #{$property}: mat-color(map_get($theme-default, $key));
  }

  .theme-sport & {
    #{$property}: mat-color(map_get($theme-sport, $key));
  }

  .theme-culture & {
    #{$property}: mat-color(map_get($theme-culture, $key));
  }

  .theme-horse & {
    #{$property}: mat-color(map_get($theme-horse, $key));
  }
}
