// Startpage exceptions
.startpage {
  nav.main {
    a {
      color: white !important;
    }

    .logo {
      .cls-3 {
        fill: white;
      }
    }

    .hamburger svg path {
      fill: white;
    }

    li.dropdown {
      li {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul,
  dl,
  blockquote,
  small,
  figure {
    color: white;
  }

  h1 {
    max-inline-size: 20ch;
  }

  p {
    max-inline-size: 50ch;
  }

  .section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: no-repeat center center;
    background-size: cover;
    padding-top: 90px;

    @include media('>=768px') {
      padding-top: 120px;
    }

    @include media('>tablet') {
      padding-top: 0;
      align-items: center;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.85);
    }

    &__content {
      position: relative;
      width: 100%;

      &--text-left,
      &--text-right {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;

        @include media('>=768px') {
          gap: 4rem;
        }

        @include media('>tablet') {
          grid-template-columns: 1fr 1fr;
        }
      }

      .col__text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        justify-content: center;

        @include media('>tablet') {
          gap: 2rem;
        }
      }

      .col__product {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0.5rem;

        shop-eventticket-highlight {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        @include media('>tablet') {
          padding: 0;
          justify-content: center;
        }
      }

      &--text-left {
        .col__text {
          order: 1;
        }

        .col__product {
          order: 2;
        }
      }

      &--text-right {
        @include media('>tablet') {
          .col__text {
            order: 2;

            @include media('>=768px') {
              padding-left: 3em;
            }

            @include media('>1440') {
              padding-left: 0;
            }
          }

          .col__product {
            order: 1;

            @include media('>=768px') {
              justify-content: flex-start;
            }
          }
        }
      }
    }
  }
}
