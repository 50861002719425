/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), url('/assets/fonts/roboto-v27-latin-regular.woff') format('woff');
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), url('/assets/fonts/roboto-v27-latin-500.woff') format('woff');
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), url('/assets/fonts/roboto-v27-latin-700.woff') format('woff');
}
/* roboto-slab-regular - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Slab'), url('/assets/fonts/roboto-slab-v13-latin-regular.woff') format('woff');
}
/* roboto-slab-700 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Slab'), url('/assets/fonts/roboto-slab-v13-latin-700.woff') format('woff');
}

::selection {
  @include theme(background-color, primary);
  color: white;
}

::marker {
  @include theme(color, primary);
}

:focus {
  outline-offset: 0.75ch;
  @include theme(outline-color, primary);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
dl,
figure,
blockquote {
  margin-block: 0;
}

small {
  font-size: max(0.5em, 0.8rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.25;
}

h1,
h2,
h3 {
  font-weight: 400;
}

h1,
.h1 {
  font-size: clamp(1.75rem, 3.5vw, 3.5rem);

  span {
    @include theme(color, primary);
    font-size: inherit !important;
    font-family: inherit !important;
  }
}

h2,
.h2 {
  font-size: clamp(1.5rem, 3vw, 2.75rem);

  span {
    @include theme(color, primary);
    font-size: inherit !important;
    font-family: inherit !important;
  }
}

h3,
.h3 {
  font-size: clamp(1.25rem, 2.5vw, 2.25rem);
}

p,
span:not(.mat-button-wrapper):not(.mat-content),
li,
dd,
dt,
strong {
  font-family: var(--ff-slab);
  font-size: clamp(0.8rem, 2vmin, 1rem);
  line-height: 1.75;
}

ol,
ul,
dl {
  display: grid;
  gap: 0.2rem;
  max-inline-size: max-content;
}

a {
  @include theme(color, primary);

  &:visited,
  &:active,
  &:focus {
    @include theme(color, primary);
  }
}

.mat-expansion-panel-header-title p,
.mat-expansion-panel-content p {
  font-size: inherit;
}

.mat-expansion-panel-header {
  height: auto !important;
  min-height: 48px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
