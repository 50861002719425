.mat-dialog-container {
  background: white;
  border-radius: 0 !important;
}

.cdk-overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.cdk-overlay-pane {
  @include media('>tablet') {
    max-width: 50vw !important;
  }

  @include media('>desktop') {
    max-width: 30vw !important;
  }

  shop-cookieconsent {
    display: grid;
    grid-row-gap: 1em;
  }

  .h3 {
    font-size: clamp(1rem, 2vw, 1.6rem);
  }

  p,
  label {
    font-size: clamp(0.675rem, 1.5vmin, 0.8rem);
    max-inline-size: unset;
  }

  button {
    background-color: transparent;
    margin: 0 0 1em 0;
  }
}
