.lightbox {
  .lb-close {
    position: fixed;
    top: 1.25em;
    right: 1.75em;
    background-size: 20px;
  }

  .lb-nav a.lb-prev,
  .lb-nav a.lb-next {
    background-size: 32px;
  }
}
