footer {
  display: flex;
  flex-direction: column;
  background-color: #1a1a1a;
  overflow: hidden;

  nav {
    display: flex !important;
    gap: 0 !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 3em !important;
    padding-bottom: 3em !important;

    @include media('>=870px') {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    .logo {
      display: none;
      margin: 0 0 2em 0;
      opacity: 0.15;

      @include media('>=870px') {
        margin: 0;
      }

      svg {
        height: 45px;

        @include media('>=870px') {
          height: 60px;
        }

        .cls-1,
        .cls-2,
        .cls-2-2,
        .cls-2-3,
        .cls-3 {
          fill: #9e9e9e;
        }
      }
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 0;
      max-inline-size: unset;
      margin: 0 0 1em 0;
      padding: 0;

      @include media('>=870px') {
        flex-direction: row;
        justify-content: space-between;
      }

      li {
        padding: 0;
        margin: 0 0 1em 0;

        @include media('>=870px') {
          margin: 0;
        }

        a {
          display: inline-block;
          padding: 0.5em 1em;
          color: white !important;
          font-family: var(--ff-sans-serif);
          line-height: 1;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }

          &:visited,
          &:active {
            color: white !important;
            text-decoration: none;
          }
        }
      }
    }

    span {
      display: block;
      padding: 0.5em 1em;
      font-size: clamp(0.75rem, 1.5vmin, 0.875rem);
      color: #948f96;
      text-align: center;

      @include media('>=870px') {
        text-align: unset;
      }
    }
  }
}

// Hide/show the logos respectively
.theme-default,
.theme-sport {
  footer .logo__sport {
    display: block;
  }
}

.theme-culture {
  footer .logo__culture {
    display: block;
  }
}

.theme-horse {
  footer .logo__horse {
    display: block;
  }
}
